import {useMutation, useQuery, useQueryClient} from "react-query"
import {toast} from "react-toastify"
import {
    addBulkMasterAWB,
    addMasterAWB,
    addShipmentBuyingPrice,
    confirmShipmentRequest, deleteMasterAWB,
    downloadReport,
    getBillShipment, getMasterAWB,
    getShipmentDetail,
    getShipments,
    groupBillShipment, sendAwbEmail,
    sendBillShipment,
    updateBillShipment, updateMasterAWB,
    updateShipment,
} from "../../../api/shipment/shipment-api"
import {useState} from "react"
import DeleteConfirmationDialog from "../../../components/modal/dialog/DeleteConfirmationDialog"

export const useGetShipments = (dateRange, date) => {
    return useQuery(
        ["getShipments", dateRange, date],
        () => getShipments(dateRange, date),
        {
            cacheTime: 10000,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    )
}

export const useGetShipmentDetail = (id) => {
    return useQuery(["getShipmentDetail"], () => getShipmentDetail(id), {
        cacheTime: 10000,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    })
}

export const useConfirmShipmentRequest = ({onSuccess}) => {
    const queryClient = useQueryClient()
    return useMutation(
        ["confirmShipmentRequests"],
        (formData) => confirmShipmentRequest(formData),
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["getShipmentDetail"])
                toast.success("Succesfully confirm shipping requests")
                onSuccess && onSuccess(data, variables, context)
            },
            onError: (err, _variables, _context) => {
                toast.error(`error: ${err.message}`)
            },
        }
    )
}

export const useUpdateShipment = ({onSuccess}) => {
    const queryClient = useQueryClient()
    return useMutation(
        ["updateShipment"],
        (formData) => updateShipment(formData),
        {
            onSuccess: (data, variables, context) => {
                localStorage.removeItem("addShipmentRequest-nepal")
                queryClient.invalidateQueries(["getShipments"])
                toast.success("Succesfully updated shipment")
                onSuccess && onSuccess(data, variables, context)
            },
            onError: (err, _variables, _context) => {
                toast.error(`error: ${err.message}`)
            },
        }
    )
}

export const useDownloadReport = ({onSuccess}) => {
    return useMutation(
        ["downloadReport"],
        (formData) => downloadReport(formData),
        {
            onSuccess: (data, variables, context) => {
                toast.success("Succesfully downloaded shipment report")
                onSuccess && onSuccess(data, variables, context)
            },
            onError: (err, _variables, _context) => {
                toast.error(`error: ${err.message}`)
            },
        }
    )
}

export const useGetBilledShipments = ({filter}) => {
    return useQuery(
        ["getBillShipments", filter],
        () => getBillShipment({filter}),
        {
            cacheTime: 10000,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    )
}

export const useGetBilledShipmentDetail = (id) => {
    return useQuery(["getBilledShipmentDetail"], () => getBillShipment({id}), {
        cacheTime: 10000,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    })
}

export const useGroupBilledShipment = ({onSuccess}) => {
    const queryClient = useQueryClient()
    return useMutation(
        ["groupBillShipment"],
        (formData) => groupBillShipment(formData),
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["getShipments"])
                queryClient.invalidateQueries(["getShipmentDetail"])
                toast.success("Succesfully billed shipment")
                onSuccess && onSuccess(data, variables, context)
            },
            onError: (err, _variables, _context) => {
                toast.error(`error: ${err.message}`)
            },
        }
    )
}

export const useUpdateBilledShipment = ({onSuccess}) => {
    return useMutation(
        ["groupBillShipment"],
        (formData) => updateBillShipment(formData),
        {
            onSuccess: (data, variables, context) => {
                toast.success("Succesfully billed shipping requests")
                onSuccess && onSuccess(data, variables, context)
            },
            onError: (err, _variables, _context) => {
                toast.error(`error: ${err.message}`)
            },
        }
    )
}

export const useAddShipmentBuyingPrice = ({onSuccess}) => {
    const queryClient = useQueryClient()
    return useMutation(
        ["addShipmentBuyingPrice"],
        (formData) => addShipmentBuyingPrice(formData),
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["getShipments"])
                queryClient.invalidateQueries(["getShipmentDetail"])
                toast.success("Succesfully added buying price")
                onSuccess && onSuccess(data, variables, context)
            },
            onError: (err, _variables, _context) => {
                toast.error(`error: ${err.message}`)
            },
        }
    )
}

export const useSendBilledShipment = ({onSuccess}) => {
    return useMutation(
        ["sendBilledShipment"],
        (formData) => sendBillShipment(formData),
        {
            onSuccess: (data, variables, context) => {
                toast.success("Succesfully sent email")
                onSuccess && onSuccess(data, variables, context)
            },
            onError: (err, _variables, _context) => {
                toast.error(`error: ${err.message}`)
            },
        }
    )
}
export const useGetMasterAWBQuery = () => {
    return useQuery(['master-awb'], () => getMasterAWB(), {})
}
export const useAddMasterAWBMutation = () => {
    const queryClient = useQueryClient()
    return useMutation((data) => addMasterAWB(data), {
        onSuccess: () => {
            queryClient.invalidateQueries('master-awb')
            toast.success("AWB added Successfully")
        },
        onError: (err, _variables, _context) => {
            toast.error(`error: ${err.message}`)
        },
    },)
}
export const useAddBulkMasterAWBMutation =()=>{
    const queryClient = useQueryClient()
    return useMutation((data)=>addBulkMasterAWB(data),{
        onSuccess:()=>{
            queryClient.invalidateQueries('master-awb')
            toast.success("AWB's added Successfully")
        },
        onError:(err,_variables,_context)=>{
            toast.error(`error: ${err.message}`)
        }
    })
}
export const useMasterAWBActionMutation = () => {
    const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false)
    const [itemIdToDelete, setItemIdToDelete] = useState(null)
    const queryClient = useQueryClient()

    const updateMasterAWBMutation = useMutation(
        (awbData) => updateMasterAWB(awbData),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('master-awb')
                toast.success('AWB Updated Successfully')
            },
            onError: (error) => {
                toast.error('error: ', error)
            },
        }
    )

    const deleteMasterAWBMutation = useMutation(
        (id) => deleteMasterAWB(id),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('master-awb')
                toast.success('AWB Deleted Successfully')
            },
            onError: (error) => {
                toast.error('error: ', error)
            },
        }
    )

    const handleUpdateMasterAWB = async (awbData) => {
        await updateMasterAWBMutation.mutateAsync(awbData)
    }

    const handleDeleteConfirmation = (id) => {
        setItemIdToDelete(id)
        setConfirmationDialogOpen(true)
    }

    const handleConfirmDelete = () => {
        deleteMasterAWBMutation.mutateAsync(itemIdToDelete)
        setConfirmationDialogOpen(false)
        toast.dismiss()
    }

    const handleCancelDelete = () => {
        setConfirmationDialogOpen(false)
    }

    return {
        updateMasterAWB: handleUpdateMasterAWB,
        isUpdatingMasterAWB: updateMasterAWBMutation.isLoading,
        deleteMasterAWB: handleDeleteConfirmation,
        isDeletingMasterAWB: deleteMasterAWBMutation.isLoading,
        ConfirmationDialog: (
            <DeleteConfirmationDialog
                open={isConfirmationDialogOpen}
                onClose={handleCancelDelete}
                onConfirm={handleConfirmDelete}
            />
        ),
    }
}

export const useSendAWBEmailMutation = () => {
    const queryClient = useQueryClient()
    return useMutation(ids => sendAwbEmail(ids), {
        onSuccess: () => {
            queryClient.invalidateQueries('master-awb')
            toast.success("Email Sent Successfully")
        },
        onError: (err, _variables, _context) => {
            toast.error(`error: ${err.message}`)
        },
    },)
}
